import useAsyncTask, { type AsyncTask } from "@/hooks/use-async-task"
import { Schema, runPrompt, runPromptWithImage } from "@/services/ai-service.ts"
import { useAuth } from "@/services/auth-service"
import { db, expensesCollection, saveExpense } from "@/services/firebase"
import { useUserProfiles } from "@/services/user-profile-service"
import type {
  CreateExpenseDto,
  CreateExpenseItemDto,
  Expense,
  ExpenseItem,
  UpdateExpenseDto
} from "@/types.ts"
import { AppError, UnauthenticatedError } from "@/utils/errors"
import { parseNumber, roundToPlaces } from "@/utils/number-utils.ts"
import { useCollection, useDoc } from "@tatsuokaniwa/swr-firestore"
import {
  Timestamp,
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  getDocs,
  or,
  query,
  updateDoc,
  where,
  writeBatch
} from "firebase/firestore"
import { useMemo } from "react"

export function useExpenses() {
  const currentUserId = useAuth().user?.uid
  const expenses = useCollection<Expense>(
    !currentUserId
      ? null
      : {
          path: "expenses",
          queryConstraints: [
            or(
              where("createdBy", "==", currentUserId),
              where("memberIds", "array-contains", currentUserId)
            )
          ]
        }
  )
  return { expenses: expenses.data, error: expenses.error }
}

export function useExpenseData(id: string | null) {
  const expense = useDoc<Expense>(id ? { path: `expenses/${id}` } : null)
  const items = useCollection<ExpenseItem>(id ? { path: `expenses/${id}/items` } : null)
  const memberIds = useMemo(() => expense.data?.memberIds ?? [], [expense.data?.memberIds])
  const members = useUserProfiles(memberIds)

  return {
    expense: expense.data,
    items: items.data,
    members: members.data
  }
}

export function useCreateExpense() {
  const task = useAsyncTask()
  const currentUserId = useAuth().user?.uid

  const run = async (file: File): Promise<Expense | null> => {
    return createExpense({ file, currentUserId, task })
  }

  return { ...task, run }
}

export async function createExpense(params: {
  file: File
  currentUserId?: string
  task: AsyncTask
}): Promise<Expense | null> {
  const { file, currentUserId, task } = params
  task.setProgress({ title: "Analyzing", subtitle: "Analyzing receipt image..." })

  try {
    if (!currentUserId) {
      throw new UnauthenticatedError()
    }

    const megabytes = roundToPlaces(file.size / 1e6, 1)
    if (megabytes > 4) {
      throw new AppError("Error uploading receipt: file too large.", {
        userSummary: `Image too large (${megabytes}MB)`,
        userMessage: "Make sure the image is smaller than 4MB."
      })
    }

    const responseText = await runPromptWithImage(
      "Return the complete details and items in this receipt image",
      file,
      { schema: ExpenseSchema }
    )

    const data = parseExpenseJson(responseText)
    if (!data) return null

    const expenseDto: CreateExpenseDto = {
      ...data.expense,
      memberIds: [currentUserId],
      createdBy: currentUserId
    }

    task.setProgress({ title: "Creating", subtitle: "Creating your new tab..." })
    return saveExpense(expenseDto, data.items)
  } catch (err) {
    let error: AppError
    if (err instanceof AppError) {
      error = err
    } else if ((err as Error).message.includes("image is not valid")) {
      error = new AppError("Error uploading receipt: invalid image", {
        userSummary: "Invalid image",
        userMessage: "Make sure the image is a JPEG, PNG, or PDF."
      })
    } else {
      error = new AppError("Error creating expense", {
        cause: err,
        userSummary: "Unable to upload receipt",
        userMessage: "Please try again."
      })
    }
    task.setError(error)
    console.error(error)
  }
  return null
}

const ExpenseItemSchema = Schema.object({
  properties: {
    name: Schema.string(),
    quantity: Schema.number(),
    total: Schema.number({
      description:
        "The total cost of the item. Should be negative if the item shows as negative on the receipt or is otherwise a discount."
    })
  }
})

const ExpenseSchema = Schema.object({
  properties: {
    name: Schema.string({
      description: "Name at the top of the receipt. Usually the merchant, store, or restaurant.",
      example: "Costco"
    }),

    date: Schema.string({ format: "date-time", example: "2024-12-29T05:38:14.328Z" }),
    subtotal: Schema.number(),
    tax: Schema.number(),
    tip: Schema.number({ description: "The dollar amount paid for tip." }),
    total: Schema.number(),

    items: Schema.array({
      description: "The purchased items on the receipt.",
      items: ExpenseItemSchema
    })
  },
  optionalProperties: ["date", "tip"]
})

export function parseExpenseJson(json: string): {
  expense: Omit<CreateExpenseDto, "memberIds" | "createdBy">
  items: CreateExpenseItemDto[]
} | null {
  const data = parseJsonResponse<AiExpense>(json)
  if (!data) return null

  const expense: Omit<CreateExpenseDto, "memberIds" | "createdBy"> = {
    name: data.name ?? "New Tab",
    date: parseDate(data.date) ?? Timestamp.now(),
    subtotal: parseNumber(data.subtotal) ?? 0,
    tax: parseNumber(data.tax) ?? 0,
    tip: parseNumber(data.tip) ?? 0,
    total: parseNumber(data.total) ?? 0
  }
  const items = (data.items ?? []).map(item => ({
    name: item.name.replace(new RegExp(`^${item.quantity} `), ""),
    quantity: item.quantity ?? 1,
    total: parseNumber(item.total) ?? 0
  })) satisfies CreateExpenseItemDto[]

  if (!expense.subtotal) {
    expense.subtotal = items.reduce((total, item) => total + item.total, 0)
  }

  return { expense, items }
}

function parseDate(dateString: string | undefined): Timestamp | undefined {
  if (!dateString) return undefined
  try {
    const date = new Date(dateString)
    const isInvalid = date.toString() === "Invalid Date"
    return isInvalid ? undefined : Timestamp.fromDate(date)
  } catch (err) {
    console.error("Error parsing date", err, dateString)
    return undefined
  }
}

export type AiExpense = Partial<{
  name: string
  date: string
  subtotal: number
  tax: number
  tip: number
  total: number
  items: ParsedItem[]
}>

type ParsedItem = { name: string; quantity?: number; total?: number }

function parseJsonResponse<T>(response: string): T | null {
  const json = response.replace(/^```json/, "").replace(/```$/, "")
  try {
    return JSON.parse(json)
  } catch (err) {
    console.log("Unable to parse response as JSON", err, json)
    return null
  }
}

export async function updateExpense(expenseId: string, updates: UpdateExpenseDto): Promise<void> {
  const expenseRef = doc(expensesCollection, expenseId)
  await updateDoc(expenseRef, updates)
}

export async function updateExpenseItem(
  expenseId: string,
  itemId: string,
  updates: Partial<ExpenseItem>
): Promise<void> {
  const expenseItemsCollection = collection(db, "expenses", expenseId, "items")
  const docRef = doc(expenseItemsCollection, itemId)

  await updateDoc(docRef, updates)
}

export async function addExpenseMember(expenseId: string, userId: string) {
  const expenseRef = doc(expensesCollection, expenseId)
  await updateDoc(expenseRef, { memberIds: arrayUnion(userId) })
}

export async function removeExpenseMember(expenseId: string, userId: string) {
  const expenseRef = doc(expensesCollection, expenseId)
  await updateDoc(expenseRef, { memberIds: arrayRemove(userId) })
  await removeUserFromAllExpenseItems(userId, expenseId)
}

export async function removeUserFromAllExpenseItems(userId: string, expenseId: string) {
  const expenseItemsCollection = collection(db, "expenses", expenseId, "items")
  const q = query(expenseItemsCollection, where("userIds", "array-contains", userId))
  const snap = await getDocs(q)
  const refs = snap.docs.map(doc => doc.ref)
  const batch = writeBatch(db)

  for (const ref of refs) {
    batch.update(ref, { userIds: arrayRemove(userId) })
  }

  await batch.commit()
}

export async function getExpenseItemNameGuesses(
  itemName: string,
  expenseName?: string
): Promise<string[] | null> {
  const responseText = await runPrompt(
    `On my ${expenseName ? `"${expenseName}" ` : ""}receipt, what are the actual full names for an item labeled "${itemName}"? Item names should be clear but short. Return a purely JSON response with an array of strings where each string is a possible name.`
  )
  return parseJsonResponse<string[]>(responseText)
}

import { useCallback, useEffect, useState } from "react"

interface Preferences {
  isUserPaletteEnabled?: boolean
  recentlyUsedMembers?: { id: string; date: string }[]
}

export function useLocalStorage() {
  const fetchPreferences = useCallback((): Preferences => {
    const savedPreferences = localStorage.getItem("preferences")
    return savedPreferences ? JSON.parse(savedPreferences) : {}
  }, [])

  const updatePreferences = (updates: Record<string, unknown>) => {
    const updated = { ...preferences, ...updates }
    localStorage.setItem("preferences", JSON.stringify(updated))
    window.dispatchEvent(new Event("prefs-update"))
  }

  const [preferences, setPreferences] = useState(fetchPreferences)

  useEffect(() => {
    const handler = () => setPreferences(fetchPreferences())
    window.addEventListener("prefs-update", handler)
    return () => window.removeEventListener("storage", handler)
  }, [fetchPreferences])

  return {
    get recentlyUsedMemberIds() {
      return preferences.recentlyUsedMembers?.map(m => m.id) ?? []
    },
    addRecentlyUserMemberId(id: string) {
      const record = { id, date: new Date().toISOString() }
      const currentRecents = preferences.recentlyUsedMembers ?? []
      const currentRecentsWithoutNew = currentRecents.filter(m => m.id !== id)
      const updatedRecents = [record, ...(currentRecentsWithoutNew.slice(0, 5) ?? [])]
      updatePreferences({ recentlyUsedMembers: updatedRecents })
    }
  }
}

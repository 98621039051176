import { Button } from "@/components/button"
import { Input } from "@/components/input"
import * as authService from "@/services/auth-service"
import { type FormEvent, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import type { AuthError } from "@/utils/errors"
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid"

export default function LoginPage() {
  const navigate = useNavigate()
  const [authError, setAuthError] = useState<AuthError | null>(null)

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const formData = new FormData(event.currentTarget)
    const data = Object.fromEntries(formData.entries()) as { email: string; password: string }

    const result = await authService.logIn(data.email, data.password)

    if (result.success) {
      navigate("/")
    } else {
      setAuthError(result.error)
    }
  }

  const authErrorMessage = useMemo(() => {
    if (!authError) return null
    if (authError.code === "auth/user-not-found" || authError.code === "auth/invalid-credential") {
      return "Invalid email or password"
    }
    return "Something went wrong. Please try again."
  }, [authError])

  return (
    <div className="flex flex-1 flex-col px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img alt="SnapTab" src="/logo.svg" className="mx-auto h-10 w-auto" />
        <h2 className="mt-2 text-center text-2xl/9 font-bold tracking-tight text-gray-900">
          Sign in to your account
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6" onSubmit={handleSubmit}>
          {authErrorMessage && (
            <div className="rounded-md bg-yellow-50 p-4">
              <div className="flex">
                <div className="shrink-0">
                  <ExclamationTriangleIcon aria-hidden="true" className="size-5 text-yellow-400" />
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-yellow-800">{authErrorMessage}</h3>
                </div>
              </div>
            </div>
          )}
          <div>
            <label htmlFor="email" className="block text-sm/6 font-medium text-gray-900">
              Email address
            </label>
            <div className="mt-2">
              <Input id="email" name="email" type="email" required autoComplete="email" />
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label htmlFor="password" className="block text-sm/6 font-medium text-gray-900">
                Password
              </label>
              <div className="text-sm">
                <a href="#" className="font-semibold text-indigo-600 hover:text-indigo-500">
                  Forgot password?
                </a>
              </div>
            </div>
            <div className="mt-2">
              <Input
                id="password"
                name="password"
                type="password"
                required
                autoComplete="current-password"
              />
            </div>
          </div>

          <div>
            <Button type="submit" color="indigo" className="w-full">
              Sign in
            </Button>
          </div>
        </form>

        {/*<p className="mt-10 text-center text-sm/6 text-gray-500">*/}
        {/*  Not a member?{" "}*/}
        {/*  <a href="#" className="font-semibold text-indigo-600 hover:text-indigo-500">*/}
        {/*    Start a 14 day free trial*/}
        {/*  </a>*/}
        {/*</p>*/}
      </div>
    </div>
  )
}

import useMemoPromise from "@/hooks/use-memo-promise"
import { BaseCollection } from "@/services/firebase.ts"
import { useCurrentUser } from "@/services/user-service"
import type { UserProfile } from "@/types.ts"
import { useMemo } from "react"

class UserProfilesCollection extends BaseCollection<UserProfile> {
  constructor() {
    super("user_profiles")
  }
}

export const userProfilesCollection = new UserProfilesCollection()

export function useUserProfiles(ids: string[]) {
  return useMemoPromise(() => userProfilesCollection.get(...ids), [ids])
}

export function useFriends() {
  const currentUser = useCurrentUser()
  const friendIds = useMemo(() => currentUser.data?.friendIds ?? [], [currentUser.data?.friendIds])

  return useUserProfiles(friendIds)
}

import Avatar from "@/components/avatar.tsx"
import type { SelectedUser } from "@/pages/expense-page/expense-page.tsx"
import type { UserProfile } from "@/types.ts"
import { firstNameLastInitialIfNeeded } from "@/utils/string-utils.ts"
import { UserGroupIcon } from "@heroicons/react/16/solid"
import { MoreHoriz } from "@mui/icons-material"
import { IconButton } from "@mui/material"
import type { ComponentProps, ReactNode } from "react"

export function UserSelectionBar(props: {
  users: UserProfile[]
  selectedUser: SelectedUser | null
  onChange: (user: SelectedUser) => void
  onManageUsersClick: () => void
}) {
  return (
    <div className="fixed bottom-0 left-0 p-4 w-full">
      <div
        className="px-4 h-16 flex items-center justify-between border rounded-[55px] bg-white/80 backdrop-blur"
        style={{ boxShadow: "0 2px 8px 0 rgba(0,0,0,0.5)" }}
      >
        <UserPalette {...props} />
      </div>
    </div>
  )
}

function UserPalette(props: ComponentProps<typeof UserSelectionBar>) {
  return (
    <div className="flex justify-between items-center flex-grow gap-1">
      {props.users?.map(user => (
        <UserPaletteItem
          key={user.id}
          name={firstNameLastInitialIfNeeded(user, props.users)}
          onClick={() => props.onChange(user)}
          isSelected={props.selectedUser !== "everyone" && props.selectedUser?.id === user.id}
        >
          <Avatar user={user} />
        </UserPaletteItem>
      ))}

      {props.users.length > 1 && (
        <UserPaletteItem
          name="Everyone"
          onClick={() => props.onChange("everyone")}
          isSelected={props.selectedUser === "everyone"}
        >
          <UserGroupIcon className="border rounded-full w-8 h-8" />
        </UserPaletteItem>
      )}

      <IconButton onClick={props.onManageUsersClick}>
        <MoreHoriz />
      </IconButton>
    </div>
  )
}

function UserPaletteItem(props: {
  name: string
  children: ReactNode
  onClick: () => void
  isSelected: boolean
}) {
  return (
    <div className="flex flex-col flex-grow items-center" onClick={props.onClick}>
      <div className={`rounded-full ${props.isSelected ? "ring" : ""}`}>{props.children}</div>
      <div className="text-xs">{props.name}</div>
    </div>
  )
}

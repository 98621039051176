export class AppError extends Error {
  public readonly userSummary: string | undefined
  public readonly userMessage: string | undefined
  public readonly code: string | undefined

  constructor(message: string, options?: AppErrorOptions) {
    super(message, { cause: options?.cause })
    this.userSummary = options?.userSummary
    this.userMessage = options?.userMessage
    this.code = options?.code
  }
}

interface AppErrorOptions {
  cause?: unknown
  userSummary?: string
  userMessage?: string
  code?: string
}

export class UnauthenticatedError extends AppError {
  constructor(message?: string) {
    super(message ?? "User not authenticated", { userSummary: "Please sign in to continue." })
  }
}

export class AuthError extends AppError {
  constructor(error: unknown) {
    if (error instanceof Error) {
      const fbError = error as { code?: string }
      super(error.message, { code: fbError.code, cause: error })
    } else {
      super("Unknown login error", { cause: error })
    }
  }
}

import { AuthContext } from "@/providers/auth-provider"
import { auth } from "@/services/firebase"
import { signInWithEmailAndPassword } from "firebase/auth"
import { useContext } from "react"
import { AuthError } from "@/utils/errors"

export async function logIn(email: string, password: string): ResultPromise<void, AuthError> {
  try {
    await signInWithEmailAndPassword(auth, email, password)
    return SuccessResult()
  } catch (err) {
    return ErrorResult(new AuthError(err))
  }
}

type Result<TSuccessData = void, TError = never> =
  | { success: true; data?: TSuccessData }
  | { success: false; error: TError }

function SuccessResult<TData>(data?: TData) {
  return { success: true, data } as const
}

function ErrorResult<TError>(error: TError) {
  return { success: false, error } as const
}

type ResultPromise<TSuccess = void, TError = never> = Promise<Result<TSuccess, TError>>

export function logOut() {
  return auth.signOut()
}

Object.assign(window, { logOut })

export function useAuth() {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider")
  }
  return context
}
